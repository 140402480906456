// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-izix-blue: #052f3f;
  --ion-color-izix-blue-contrast: #ffffff;
  --ion-color-izix-blue-100: #bdd3db;
  --ion-color-izix-blue-200: #5d8896;
  --ion-color-izix-blue-300: #375965;

  --ion-color-izix-green: #15856f;
  --ion-color-izix-green-600: #116a59;

  --ion-color-izix-dark-teal: #21cfae;

  --ion-color-izix-teal: #35efca;

  --ion-color-izix-black: #262626;

  --ion-color-izix-info: #094f81;
  --ion-color-izix-success: #15856f;
  --ion-color-izix-alert: #e88326;
  --ion-color-izix-error: #b20000;

  --ion-color-gray-800: #303030;
  --ion-color-gray-700: #404040;
  --ion-color-gray-500: #606060;
  --ion-color-gray-400: #808080;
  --ion-color-gray-300: #606060;
  --ion-color-gray-200: #d8d8d8;
  --ion-color-gray-100: #f0f0f0;


  /** base **/
  --ion-color-base: var(--ion-color-izix-blue);

  /** primary **/
  --ion-color-primary: var(--ion-color-izix-blue);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: var(--ion-color-izix-blue-contrast);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--ion-color-izix-blue);
  --ion-color-primary-tint: var(--ion-color-izix-blue-200);

  /** secondary **/
  --ion-color-secondary: var(--ion-color-izix-green);
  --ion-color-secondary-rgb: 21, 133, 111;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: var(--ion-color-izix-green-600);
  --ion-color-secondary-tint: var(--ion-color-izix-dark-teal);

  /** tertiary **/
  --ion-color-tertiary: var(--ion-color-izix-teal);
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: var(--ion-color-izix-teal);
  --ion-color-tertiary-tint: var(--ion-color-izix-teal);

  /** success **/
  --ion-color-success: var(--ion-color-izix-success);
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: var(--ion-color-izix-alert);
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: var(--ion-color-izix-error);
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: var(--ion-color-izix-black);
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** transparent **/
  --ion-color-transparent: transparent;

  /** Microsoft **/
  --ion-color-microsoft-white: #fff;
  --ion-color-microsoft-gray-100: #8d8d8d;
  --ion-color-microsoft-gray-200: #c7c7c7;
  --ion-color-microsoft-gray-300: #f5f5f5;
  --ion-color-microsoft-gray-800: #242424;
}
